// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../images/icons.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___, { hash: "#twitter" });
var code = "<section class=\"widget panel b-a m-t-sm\" ng-show=\"twitterName\"> <div class=\"panel-heading b-b small\"> <div class=\"pull-right text-xs align\"> <a href=\"https://twitter.com/{{ twitterName }}\" target=\"_blank\"> {{ 'app.public.home.follow_us' | translate }} <svg viewBox=\"0 0 24 24\" width=\"32px\"> <use href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </svg> </a> </div> <h2 translate>{{ 'app.public.home.latest_tweets' }}</h2> </div> <div id=\"twitter\" class=\"widget-content no-bg auto\"></div> </section> ";
// Exports
var _module_exports = code;;
var path = '/home/twitter.html';
var angular = require('angular');
angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;